import { ref, watch, computed } from '@vue/composition-api';
import { title } from '@core/utils/filter';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import moment from 'moment';
import store from '@/store';

export default function useSessionsList(kind) {
  // Use toast
  const toast = useToast();

  const filterType = ref(undefined);

  const refSessionListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'name', sortable: true },
    {
      key: 'profiles',
      label: 'Students',
    },
    {
      key: 'startDate',
      label: 'Starts',
      formatter: (value) => {
        if (!value) return 'n/a';
        return moment(value).utc().format('L');
      },
      sortable: true,
    },
    {
      key: 'endDate',
      label: 'Ends',
      formatter: (value) => {
        if (!value) return 'n/a';
        return moment(value).utc().format('L');
      },
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'Created At',
      formatter: (value) => {
        return moment(value).format('L');
      },
      sortable: true,
    },
    { key: 'visibility', sortable: true },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ];
  const perPage = ref(10);
  const totalSessions = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref('');
  const sortBy = ref('startDate');
  const isSortDirDesc = ref(false);

  const visibilityFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refSessionListTable.value ? refSessionListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSessions.value,
    };
  });

  const refetchData = () => {
    refSessionListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, visibilityFilter, statusFilter], () => {
    refetchData();
  });

  const fetchSessions = (ctx, callback) => {
    const sbv = sortBy.value === 'id' ? '_id' : sortBy.value;
    const sb = `${sbv}:${isSortDirDesc.value ? 'desc' : 'asc'}`;

    const pl = {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sortBy: sb,

      populate: 'courses,courses.classes',
    };

    if (visibilityFilter.value) {
      pl.visibility = visibilityFilter.value;
    }
    if (statusFilter.value) {
      pl.status = statusFilter.value;
    }

    store
      .dispatch(`app-roster/fetch${filterType.value}Sessions`, pl)
      .then((response) => {
        const { results, totalResults } = response.data;

        callback(results);
        totalSessions.value = totalResults;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching sessions list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === 'subscriber') return 'primary';
    if (role === 'author') return 'warning';
    if (role === 'maintainer') return 'success';
    if (role === 'editor') return 'info';
    if (role === 'admin') return 'danger';
    return 'primary';
  };

  const resolveUserRoleIcon = (role) => {
    if (role === 'subscriber') return 'SessionIcon';
    if (role === 'author') return 'SettingsIcon';
    if (role === 'maintainer') return 'DatabaseIcon';
    if (role === 'editor') return 'Edit2Icon';
    if (role === 'admin') return 'ServerIcon';
    return 'SessionIcon';
  };

  const resolveSessionVisibilityVariant = (status) => {
    if (status === 'internal') return 'primary';
    if (status === 'public') return 'info';
    return 'primary';
  };

  const resolveSessionStatusVariant = (status) => {
    if (status === 'created') return 'secondary';
    if (status === 'enrolling') return 'primary';
    if (status === 'waiting') return 'warning';
    if (status === 'live') return 'info';
    if (status === 'completed') return 'success';
    return 'primary';
  };

  const statusOptions = [
    { label: 'Created', value: 'created' },
    { label: 'Enrolling', value: 'enrolling' },
    { label: 'Waiting', value: 'waiting' },
    { label: 'Live', value: 'live' },
    { label: 'Completed', value: 'completed' },
    { label: 'Archived', value: 'archived' },
  ];

  const visibilityOptions = [
    { label: 'Internal', value: 'internal' },
    { label: 'Public', value: 'public' },
  ];

  return {
    fetchSessions,
    tableColumns,
    perPage,
    currentPage,
    totalSessions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSessionListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveSessionVisibilityVariant,
    resolveSessionStatusVariant,
    refetchData,

    statusOptions,
    visibilityOptions,

    // Extra Filters
    visibilityFilter,
    statusFilter,

    //
    filterType,
  };
}
